<!--
 * @Description: 申请人范围选择
 * @Author: 琢磨先生
 * @Date: 2022-09-18 06:06:09
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-18 08:40:42
-->
<template>
  <el-button type="primary" link @click="onOpen">选择人员范围</el-button>
  <el-dialog
    title="适用范围选择"
    v-model="visibleDialog"
    width="1000px"
    append-to-body
    draggable
    custom-class="applicant_dialog"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div style="height:40vh;">
      <el-tabs v-model="tabIndex" tab-position="top">
        <el-tab-pane label="部门" :name="0">
          <el-scrollbar>
            <el-tree
              ref="deptTree"
              :data="dept_list"
              node-key="id"
              :expand-on-click-node="false"
              highlight-current
              show-checkbox
              check-strictly
              :props="{ children: 'children', label: 'name' }"
              default-expand-all
              @check-change="deptCheckChange"
            />
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :name="1" label="人员">
          <el-row :gutter="20" style="height:100%">
            <el-col :span="10" style="height:100%">
              <el-scrollbar>
                <el-tree
                  ref="tree"
                  :data="dept_list"
                  node-key="id"
                  :expand-on-click-node="false"
                  highlight-current
                  :props="{ children: 'children', label: 'name' }"
                  default-expand-all
                  @current-change="treeNodeChange"
                />
              </el-scrollbar>
            </el-col>
            <el-col :span="14" style="height:100%">
              <el-table
                ref="empTable"
                :data="emp_list"
                row-key="id"
                @selection-change="empSelectionChange"
              >
                <el-table-column type="selection" width="80" reserve-selection></el-table-column>
                <el-table-column label="姓名" prop="chs_name">
                  <!-- <template #header>
                    <el-input v-model="query.q" placeholder="关键字查询部门人员" @input="inputChange"></el-input>
                  </template>-->
                  <template #default="scope">{{scope.row.chs_name}}{{scope.row.mobile_phone}}</template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :name="2" label="岗位角色">
          <el-table
            ref="postTable"
            :data="post_list"
            row-key="id"
            height="100%"
            @selection-change="postSelectionChange"
          >
            <el-table-column type="selection" width="80" reserve-selection></el-table-column>
            <el-table-column label="名称" prop="name" width="200"></el-table-column>
            <el-table-column label="描述" prop="description"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div style="margin-top:20px;">
      <el-alert type="info" :closable="false" title="已选择">
        <el-tag
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in selection_dept_list"
          :key="item.id"
          closable
          type="danger"
          @close="onDeptRemove(i)"
        >
          <div style="display:flex;align-items: center;">
            <el-icon>
              <FolderOpened />
            </el-icon>
            部门：{{item.name}}
          </div>
        </el-tag>
        <el-tag
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in selection_emp_list"
          :key="item.id"
          closable
          type="success"
          @close="onEmpRemove(i)"
        >
          <div style="display:flex;align-items: center;">
            <el-icon>
              <UserFilled />
            </el-icon>
            人员：{{item.chs_name}}
          </div>
        </el-tag>
        <el-tag
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in selection_post_list"
          :key="item.id"
          closable
          @close="onPostRemove(i)"
        >
          <div style="display:flex;align-items: center;">
            <el-icon>
              <User />
            </el-icon>
            角色：{{item.name}}
          </div>
        </el-tag>
      </el-alert>
    </div>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dept_api from "@/http/dept_api";
import post_api from "@/http/post_api";

export default {
  data() {
    return {
      visibleDialog: false,
      tabIndex: 0,
      query: {
        dept_id: "",
        q: "",
      },
      dept_list: [],
      post_list: [],
      emp_list: [],

      //已选择的人员数据
      selection_emp_list: [],
      //选择的部门数据
      selection_dept_list: [],
      //选中的方位角色
      selection_post_list: [],
    };
  },
  emits: ["success"],
  created() {
    this.loadDept();
    this.loadPost();
  },

  methods: {
    /**
     * 打开
     */
    onOpen() {
      this.visibleDialog = true;
    },
    /**
     *
     */
    loadDept() {
      dept_api.get_dept().then((res) => {
        if (res.code == 0) {
          this.dept_list = res.data;
          if (this.dept_list.length > 0) {
            this.$nextTick(() => {
              if (this.$refs.tree) {
                this.$refs.tree.setCurrentKey(this.dept_list[0].id);
              }
            });
            this.query.dept_id = this.dept_list[0].id;
            this.loadEmp();
          }
        }
      });
    },
    /**
     * 加载岗位数据
     */
    loadPost() {
      post_api.get_posts().then((res) => {
        if (res.code == 0) {
          this.post_list = res.data;
        }
      });
    },
    /**
     * 加载人员
     */
    loadEmp() {
      this.$http.post("seller/v1/emp/by_dept", this.query).then((res) => {
        if (res.code == 0) {
          this.emp_list = res.data;
        }
      });
    },

    /**
     * 部门选择
     */
    deptCheckChange() {
      //   console.log(nodes);
      var nodes = this.$refs.deptTree.getCheckedNodes();
      console.log(nodes);
      this.selection_dept_list = nodes;
    },
    /**
     * 移除部门
     */
    onDeptRemove(i) {
      var node = this.selection_dept_list.splice(i, 1)[0];
      this.$refs.deptTree.setChecked(node, false);
    },
    /**
     * 岗位角色选择
     */
    postSelectionChange(nodes) {
      this.selection_post_list = nodes;
    },
    /**
     * 移除选择
     */
    onPostRemove(i) {
      var node = this.selection_post_list.splice(i, 1)[0];
      this.$refs.postTable.toggleRowSelection(node, false);
    },
    /**
     * 人员选择部门更改
     */
    treeNodeChange(node) {
      this.query.dept_id = node.id;
      this.loadEmp();
    },
    /**
     * 人员选择更改
     */
    empSelectionChange(nodes) {
      this.selection_emp_list = nodes;
    },
    /**
     * 移除选择的人员
     */
    onEmpRemove(i) {
      var node = this.selection_emp_list.splice(i, 1)[0];
      this.$refs.empTable.toggleRowSelection(node, false);
    },
    /**
     * 确定
     */
    onConfirm() {
      var list = [];
      this.selection_dept_list.forEach((item) => {
        list.push({
          id: item.id,
          type: 1,
          name: item.name,
        });
      });
      this.selection_emp_list.forEach((item) => {
        list.push({
          id: item.id,
          type: 0,
          name: item.chs_name,
        });
      });
      this.selection_post_list.forEach((item) => {
        list.push({
          id: item.id,
          type: 2,
          name: item.name,
        });
      });
      this.$emit("success", list);
      this.visibleDialog = false;
    },
  },
};
</script>

<style >
.applicant_dialog .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.applicant_dialog .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}
.applicant_dialog .el-tab-pane {
  height: 100%;
}
</style>