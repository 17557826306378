/*
 * @Description: 岗位角色api
 * @Author: 琢磨先生
 * @Date: 2022-05-09 16:36:26
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-30 15:21:22
 */
import http from "./axios_init";

export default {
  /**
   * 查询岗位列表
   * @returns
   */
  get_posts() {
    return http.get("seller/v1/post");
  },

  /**
   * 获取所有的岗位角色
   */
  get_all(){
    return http.get("seller/v1/post/all");
  }
};
